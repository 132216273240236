import logo from './logo.svg';
import './App.css';
import React, { useState, useCallback } from 'react';
import Quiz from './lib/Quiz';
import { quiz as quiz_stephane_cr } from './questions/stephane/course_review_exam/quiz_stephane_cr';
import { quiz as quiz_stephane_practice1 } from './questions/stephane/practice_exams/quiz_stephane_practice1';
import { quiz as quiz_stephane_practice2 } from './questions/stephane/practice_exams/quiz_stephane_practice2';
import { quiz as quiz_stephane_practice3 } from './questions/stephane/practice_exams/quiz_stephane_practice3';
import { quiz as quiz_stephane_practice4 } from './questions/stephane/practice_exams/quiz_stephane_practice4';
import { quiz as quiz_stephane_practice5 } from './questions/stephane/practice_exams/quiz_stephane_practice5';
import { quiz as quiz_stephane_practice6 } from './questions/stephane/practice_exams/quiz_stephane_practice6';
import { quiz as quiz_neil_cr } from './questions/neil/course_review_exam/quiz_neil_cr';
import { quiz as quiz_neil_practice1 } from './questions/neil/practice_exams/quiz_neil_practice1';
import { quiz as quiz_neil_practice2 } from './questions/neil/practice_exams/quiz_neil_practice2';
import { quiz as quiz_neil_practice3 } from './questions/neil/practice_exams/quiz_neil_practice3';
import { quiz as quiz_neil_practice4 } from './questions/neil/practice_exams/quiz_neil_practice4';
import { quiz as quiz_neil_practice5 } from './questions/neil/practice_exams/quiz_neil_practice5';
import { quiz as quiz_neil_practice6 } from './questions/neil/practice_exams/quiz_neil_practice6';

const options = [
  { name: "Stephane CR", obj: quiz_stephane_cr },
  { name: "Stephane Practice1", obj: quiz_stephane_practice1 },
  { name: "Stephane Practice2", obj: quiz_stephane_practice2 },
  { name: "Stephane Practice3", obj: quiz_stephane_practice3 },
  { name: "Stephane Practice4", obj: quiz_stephane_practice4 },
  { name: "Stephane Practice5", obj: quiz_stephane_practice5 },
  { name: "Stephane Practice6", obj: quiz_stephane_practice6 },
  { name: "Neil CR", obj: quiz_neil_cr },
  { name: "Neil Practice1", obj: quiz_neil_practice1 },
  { name: "Neil Practice2", obj: quiz_neil_practice2 },
  { name: "Neil Practice3", obj: quiz_neil_practice3 },
  { name: "Neil Practice4", obj: quiz_neil_practice4 },
  { name: "Neil Practice5", obj: quiz_neil_practice5 },
  { name: "Neil Practice6", obj: quiz_neil_practice6 }
];

// https://stackoverflow.com/questions/23616226/insert-html-with-react-variable-statements-jsx

function App() {
  //const [quizResult, setQuizResult] = useState();

  const handleClick = useCallback(
    (quiz_param) => () => {
      console.log("Selected QUIZ: ", quiz_param)
      setSelectedQuiz(quiz_param)
      setCurrentKey(c => c + 1);
    },
    [],
  )

  function handleChange(e) {
    let newQuizIndex = e.target.value
    console.log('You changed selection to:' + newQuizIndex);
    setSelectedQuiz(options[newQuizIndex].obj)
    setCurrentKey(c => c + 1);
  }

  const [selectedQuiz, setSelectedQuiz] = useState(quiz_stephane_cr);
  const [currentKey, setCurrentKey] = useState(1);

  return (
    <div>
      {currentKey}
      <br></br>

      <select onChange={handleChange}>
        {options.map((option, index) => (
          <option key={index} value={index}>
            {option.name}
          </option>
        ))}
      </select>

      {/* <select onChange={handleChange}>
        <option value={JSON.stringify(quiz_stephane_cr)}>Stephane CR</option>
        <option value={JSON.stringify(quiz_stephane_practice1)}>Stephane Practice1</option>
        <option value={JSON.stringify(quiz_stephane_practice2)}>Stephane Practice2</option>
        <option value={JSON.stringify(quiz_stephane_practice3)}>Stephane Practice3</option>
        <option value={JSON.stringify(quiz_stephane_practice4)}>Stephane Practice4</option>
        <option value={JSON.stringify(quiz_stephane_practice5)}>Stephane Practice5</option>
        <option value={JSON.stringify(quiz_stephane_practice6)}>Stephane Practice6</option>
        <option value={JSON.stringify(quiz_neil_practice4)}>Grapefruit</option>
        <option value={JSON.stringify(quiz_neil_practice5)}>Lime</option>
        <option value="coconut">Coconut</option>
        <option value="mango">Mango</option>
      </select> */}


      {/* <button onClick={handleClick(quiz_stephane_cr)}>
        Stephane CR
      </button>
      <button onClick={handleClick(quiz_stephane_practice1)}>
        Stephane Practice1
      </button>  
      <button onClick={handleClick(quiz_stephane_practice2)}>
        Stephane Practice2
      </button>  
      <button onClick={handleClick(quiz_stephane_practice3)}>
        Stephane Practice3
      </button>
      <button onClick={handleClick(quiz_stephane_practice4)}>
        Stephane Practice4
      </button>
      <button onClick={handleClick(quiz_stephane_practice5)}>
        Stephane Practice5
      </button>
      <button onClick={handleClick(quiz_stephane_practice6)}>
        Stephane Practice6
      </button>
      <br></br>
      <button onClick={handleClick(quiz_neil_cr)}>
        Neil CR
      </button>
      <button onClick={handleClick(quiz_neil_practice1)}>
        Neil Practice1
      </button>  
      <button onClick={handleClick(quiz_neil_practice2)}>
        Neil Practice2
      </button>  
      <button onClick={handleClick(quiz_neil_practice3)}>
        Neil Practice3
      </button>
      <button onClick={handleClick(quiz_neil_practice4)}>
        Neil Practice4
      </button>
      <button onClick={handleClick(quiz_neil_practice5)}>
        Neil Practice5
      </button>
      <button onClick={handleClick(quiz_neil_practice6)}>
        Neil Practice6
      </button> */}
      <Quiz
        key={currentKey}
        quiz={selectedQuiz}
        showInstantFeedback={true}
        //continueTillCorrect={true}
        allowNavigation={true}
      //onComplete={setQuizResult}
      //onQuestionSubmit={(obj) => console.log('user question results:', obj)}
      //disableSynopsis
      />

      {/* <Quiz
        onClick={console.log("haha test1")}
        quiz={quiz_stephane_cr}
        showInstantFeedback={true}
        //continueTillCorrect={true}
        allowNavigation={true}
      //onComplete={setQuizResult}
      //onQuestionSubmit={(obj) => console.log('user question results:', obj)}
      //disableSynopsis
      />
      <Quiz
        onClick={console.log("haha test2")}
        quiz={quiz_stephane_practice1}
        showInstantFeedback={true}
        //continueTillCorrect={true}
        allowNavigation={true}
      //onComplete={setQuizResult}
      //onQuestionSubmit={(obj) => console.log('user question results:', obj)}
      //disableSynopsis
      /> */}
    </div>


  );
}

export default App;
